import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {Mask, Toast} from "antd-mobile";
import html2canvas from "html2canvas";
import {throttle} from 'library/util/publicFun';
import styles from "./styles.module.scss";
import QRCode from "qrcode.react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import icon_share_copy from "images/icon_share_copy.png";
import icon_share_down from "images/icon_share_down.png";
import icon_share_pyp from "images/icon_share_pyp.png";
import icon_share_wechat from "images/icon_share_wechat_1.jpg";
import bgImage from "images/womenDay/bgImage.png";
import Library from "library";
import { Actions } from 'reduxs';

const {JsBridge} = Library;

const ShareModal = ({closeMask, activityCode, inviteCode, shareConfig}) => {
    const [visible, setVisible] = useState(true);
    const domRef = useRef(null);
    const host =
        process.env.NODE_ENV == "development"
            ? window.location.origin
            : process.env.REACT_APP_GKT_LINK;

    const [shareLink, setShareLink] = useState(
        `${host}/front/activities/womenDay`
    );
    useEffect(() => {
        JsBridge.getCurrentUserInfo().then((res) => {
            setShareLink(
                `${host}/front/activities/womenDay?clientId=${res?.clientId}`
            );
        });
    }, []);
    const createShareImage = () => {
        return new Promise(async (resolve) => {
            html2canvas(domRef.current, {
                allowTaint: true,
                useCORS: true,
                scrollX: 0,
                scrollY: 0
            }).then(async (canvas) => {
                const result = canvas.toDataURL('image/jpeg', 0.8);
                resolve(result);
            }).catch(() => {
                resolve(false);
            });
        })
    }

    // 生成分享图片
    const getShareImagePath = () => {
        return new Promise(async (resolve) => {
            if (!domRef.current) {
                resolve(false);
            }
            let base64 = await createShareImage();
            if (base64) {
                let res = await JsBridge.saveImgBase64(base64).catch(ex => false);
                if (!res) {
                    JsBridge.runAction('alert', 'error', ['图片保存到本地失败']);
                    resolve(false);
                } else {
                    resolve(res.tempFilePath);
                }
            } else {
                resolve(false);
            }
        })
    }

    // 微信分享
    const wxShare = throttle(async () => {
        let shareToast = Toast.show({
            icon: 'loading',
            content: '加载中…',
        })
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.share({
                title: shareConfig.sharingTitle || "标题",
                text: shareConfig.sharingDescription || "这是描述",
                icon: shareConfig.sharingPicture || `${host}${bgImage}`,
                link: shareLink || shareConfig.sharingUrl,
                platform: 1,
            }).then(function (result) {
                shareToast && shareToast.close();
            });
        }
    }, 3000)

    // 微信朋友圈分享
    const momentsShare = throttle(async () => {
        let shareToast = Toast.show({
            icon: 'loading',
            content: '加载中…',
        })
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.share({
                title: shareConfig.sharingTitle || "",
                text: shareConfig.sharingDescription || "",
                icon: shareConfig.sharingPicture || `${host}${bgImage}`,
                link: shareLink || shareConfig.sharingUrl,
                platform: 2,
            }).then(function (result) {
                shareToast && shareToast.close();
            });
        }
    }, 3000);

    // 下载图片
    const downloadImg = throttle(async () => {
        let shareToast = Toast.show({
            icon: 'loading',
            content: '加载中…',
        })
        let filePath = await getShareImagePath();
        if (JsBridge.hasWebViewBridge()) {
            JsBridge.requestPermission("requestAlbum")
            JsBridge.saveImageToPhotosAlbum(filePath).then(function (res) {
                shareToast && shareToast.close()
                if (res) {
                    Toast.show("图片保存成功");
                } else {
                    Toast.show("图片保存失败")
                }
            })
        } else {
            let a = document.createElement("a")
            document.body.appendChild(a)
            a.download = `image-${new Date().getTime()}`
            a.href = filePath
            a.click()
            document.body.removeChild(a)
            shareToast && shareToast.close()
        }
    }, 3000)

    const cancel = () => {
        setVisible(false);
        closeMask();
    }

    return (
        <Mask visible={visible} onMaskClick={cancel} opacity={0.7} style={{
            overflow: 'auto',
            paddingBottom: '10px'
        }}>
            <div className={styles.share_container}>
                <div className={styles.share_body}>
                    <div className={styles.share_content} ref={domRef}>
                        <div className={styles.share_img_box}>
                            <img className={styles.share_avatar} src={bgImage} alt="avatar"/>
                        </div>
                        <div className={styles.share_detail}>
                            <QRCode
                                value={shareLink}
                                size={120}
                                fgColor="#000000"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.opt}>
                    <div className={styles.wechat} onClick={wxShare}>
                        <img src={icon_share_wechat} alt=""/>
                        <span>微信好友</span>
                    </div>
                    <div className={styles.share} onClick={momentsShare}>
                        <img src={icon_share_pyp} alt=""/>
                        <span>朋友圈</span>
                    </div>
                    <CopyToClipboard text={shareLink}
                                     onCopy={async () => {
                                         await Actions.alert.show('复制成功', 1000);
                                     }}>
                        <div className={styles.copy}>
                            <img src={icon_share_copy} alt=""/>
                            <span>复制链接</span>
                        </div>
                    </CopyToClipboard>
                    <div className={styles.download} onClick={downloadImg}>
                        <img src={icon_share_down} alt=""/>
                        <span>下载图片</span>
                    </div>
                </div>
                <div className={styles.cancel_btn} onClick={cancel}>
                    <span>取消</span>
                </div>
            </div>
        </Mask>
    )
}

ShareModal.open = ({activityCode, inviteCode, shareConfig}) => {
    let div = null;
    if(!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(
        <ShareModal
            activityCode={activityCode}
            inviteCode={inviteCode}
            shareConfig={shareConfig}
            closeMask={close}
        />, div);
}

export default ShareModal;