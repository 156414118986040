/**
 * @description 2025 38妇女节
 * @returns route: /front/activities/womenDay
 */
import Styles from './styles.module.scss'
import {Header} from "components";
import React, {useEffect, useState} from "react";
import Library from "library";
import gedifan from "../../../../images/womenDay/gedifan.png"
import banmouhuatian from '../imgs/banmouhuatian.png'
import costa from '../imgs/costa.png'
import dyson from '../imgs/dyson.png'
import shenmo from '../imgs/shenmo.png'
import ytln from '../imgs/ytln.png'
import sk2 from '../imgs/sk2.png'
import amn from '../imgs/amn.png'
import lankou from '../imgs/lankou.png'
import tf from '../imgs/tf.png'
import ysl from '../imgs/ysl.png'
import aimashi from '../imgs/aimashi.png'
import lan from '../imgs/lan.png'
import helianna from '../imgs/helianna.png'
import ShopRecommend from "../components/shopReommend";
import Service from "../service";
import RulesModal from "../components/rulesModal";
import ShareModal from "../components/shareModal/index";
import Interceptor from "../../springFestival/components/interceptor";

const { JsBridge, Platform } = Library;
export default () => {
    const { clientId } = Library.Util.url.paramsToObj();
    const [shareConfig, setShareConfig] = useState({});

    const thirdLogs = [gedifan,costa,dyson,banmouhuatian,ytln,shenmo]
    const secondLogs = [sk2,lankou,ysl,tf,amn]
    const firstLogos = [aimashi,lan,helianna]

    useEffect(() => {
        if (clientId) {
            Service.shareActivityTask({ clientId });
        }
        loadData();
    }, []);
    const loadData = async () => {
        const result = await Service.getShareConfig();
        if (result?.data) {
            setShareConfig(result.data);
        } else {
            setShareConfig({ status: 0 });
        }
    };
    // 活动规则
    const ruleClick = () => {
        RulesModal.open({
            btnStyle: {
                background: '#7464B9',
            },
            activityCode: Service.activityCode,
            codeType: "activityRule",
            code: Service.activityCode,
        });
    };
    const shareClick = () => {
        ShareModal.open({
            activityCode: Service.activityCode,
            inviteCode: '',
            shareConfig,
        });
    };
    return (
        <div className={Styles.magpiePage}>
            {
                JsBridge.hasWebViewBridge() &&
                <Header bgColor={'transparent'} />
            }
            <div className={Styles.magpiePage_wraper}>
                <div className={Styles.bgTop}>
                    <div className={Styles.tag}></div>
                    {/*顶部按钮布局*/}
                    <div className={Styles.activityBtns}>
                        <div onClick={ruleClick}>规则</div>
                        {
                            shareConfig.status === 0 ? null : JsBridge.hasWebViewBridge()  ?
                                <div onClick={shareClick}>分享</div> : null
                        }
                    </div>
                </div>
                {/*logo区域*/}
                <div className={Styles.centerLogoBox}>
                    <div className={Styles.logoBox}>
                        <div className={Styles.log3}>
                            <div className={Styles.bottomRowBox}>
                                {firstLogos.map((item, index) => <div key={index} className={Styles.arcBox3}>
                                    <img src={item} alt=""/>
                                </div>)}
                            </div>
                        </div>
                        <div className={Styles.log2}>
                            <div className={Styles.bottomRowBox}>
                                {secondLogs.map((item, index) => <div key={index} className={Styles.arcBox2}>
                                    <img style={{ width: index === 3 ? '100%' : undefined}} src={item} alt=""/>
                                </div>)}
                            </div>
                        </div>
                        <div className={Styles.log1}>
                            <div className={Styles.bottomRowBox}>
                                {thirdLogs.map((item, index) => <div key={index} className={Styles.arcBox}>
                                    <img src={item} alt=""/>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
                {/* 商品推荐 */}
                <ShopRecommend />
                {/* 底部logo */}
                <div className={Styles.footer_logo}>
                    <img src={require('images/womenDay/bottom-logo.png')} alt="" />
                </div>
                {Platform.check.isMiniprogram() || Platform.check.isApp() ? null : (
                    <Interceptor />
                )}
            </div>
        </div>
    )
}