import React, {useEffect, useState} from "react";
import Services from "../../../../../services";
import {Mask} from "antd-mobile";
import ReactDOM from "react-dom";
import  Styles from './styles.module.scss'

const RulesModal = ({onClose, btnStyle={}, activityCode, codeType, code}) => {
    const [visible, setVisible] = useState(true);
    const [activityRules, setActivityRules] = useState(null);

    useEffect(() => {
        visible && getActivityRules();
    }, [visible]);

    const handleClick = () => {
        setVisible(false);
        onClose && onClose();
    }

    const getActivityRules = async () => {
        const ServiceModule = window.JsBridge.hasWebViewBridge() ? 'MainPage': 'Common'
        const res = await Services(ServiceModule, { code: '101193' }, {
            activityCode,
            codeType,
            code,
        });
        if (!!res && res?.status === 1 && !!res?.data && !!res?.data?.activityRule) {
            setActivityRules(res?.data?.activityRule);
        }
        if (!!res && res?.status === 1 && !!res?.data && res?.data?.result) {
            setActivityRules(res.data.result.activityRule);
        }

    }

    return <Mask visible={visible} onMaskClick={() => handleClick()}>
        <div className={Styles.goddessRulesModal}>
            <div className={Styles.goddessModalContainer}>
                <div className={Styles.goddessModalTitle}>规则</div>
                <div className={Styles.goddessModalContent}>
                    {activityRules?.otherSign}
                </div>
                <div className={Styles.goddessRulesBtn} style={{...btnStyle}} onClick={handleClick}>确认</div>
            </div>
        </div>
    </Mask>
}

RulesModal.open = ({btnStyle={}, activityCode, codeType, code}) => {
    let div = null;
    if(!div) {
        div = document.createElement('div');
    }
    document.body.appendChild(div);
    const close = () => {
        div.remove();
    }
    ReactDOM.render(<RulesModal
        btnStyle={btnStyle}
        onClose={close}
        activityCode={activityCode}
        codeType={codeType}
        code={code}
    />, div);
}

export default RulesModal